import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IPlan, InitialState } from '../../../redux/slice';
import { createAppAsyncThunk } from '../../../../hooks';
import { SnackBarActions } from '../../../../Shared/SnackBar/slice';

export const outdoorHashCalculateBudgetAllocator = createAppAsyncThunk<
  any,
  {hashName: string}
>(
  "plan-requests/outdoorHashCalculateBudgetAllocator",
  async (data, { getState, extra: { API }, dispatch }) => {
    const state: any = getState();
    const planId = state?.planRequests?.plan?._id;
    try {
      if (planId && data.hashName) {
        const response = await API.put(
          `/plan-requests/${planId}/hash/outdoor/budget-allocator`
        );

        return {
          updatedPlan: response.data?.updatedPlanRequest,
          hashName: data?.hashName
        };
      }
      return undefined;
    } catch (err: any) {
      let response = { ...err.response };
      dispatch(
        SnackBarActions.openSnack({
          message: response?.data || 'Error Occured.',
          open: true,
          type: 'error'
        })
      );
      throw err;
    }
  }
);

export const outdoorHashMappingExtraReducers = (
  builder: ActionReducerMapBuilder<InitialState>
) => {
  builder
    .addCase(outdoorHashCalculateBudgetAllocator.fulfilled, (state, action) => {
      if (action?.payload?.updatedPlan && action?.payload?.hashName) {
        state.plan.currentState = action.payload.updatedPlan.currentState;
        state.plan[action.payload.hashName as keyof IPlan] =
          action.payload.updatedPlan[action.payload.hashName];
      }
    });
};
