import { Suspense, useEffect } from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchPlan } from '../redux/slice';
import HashMenu from './HashMenu';
import { stages, newStage, outdoorHashChildRoutes } from './config';

const OutdoorHash = () => {
  const isLoading = useAppSelector(
    (state: any) => state?.planRequests?.planLevelIsLoading
  );

  const dispatch = useAppDispatch(),
    { pathname } = useLocation();
  let { planId } = useParams();

  useEffect(() => {
    if (planId && pathname) {
      dispatch(fetchPlan(planId));
    }
  }, [planId, pathname, dispatch]);

  return (
    <Stack
      direction="row"
      sx={{ backgroundColor: '#fff', height: 'calc(100% - 64px)' }}
    >
      <HashMenu
        config={outdoorHashChildRoutes}
        newStage={newStage}
        stages={stages}
      />
      {isLoading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={6}
          sx={{ marginTop: '120px', height: '300px', width: '100%' }}
        >
          <Typography>Loading...</Typography>
          <CircularProgress size={20} />
        </Stack>
      ) : (
        <Stack sx={{ flexGrow: 1, backgroundColor: '#fff', overflow: 'auto' }}>
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </Stack>
      )}
    </Stack>
  );
};

export default OutdoorHash;
